/* Footer Styling */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  color: white;
  text-align: center;
  width: 100%;
  padding: 5px;
  font-size: 12px;
}

.footer>a {
  color: white;
  text-decoration: none;
}

/* Header Tab Styling */
.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  /*Removes Bullet Points*/
}

.home,
.about,
.contact,
.privacy {
  margin-right: 2rem;
}

/* Font Styling */
@font-face {
  font-family: 'InterTightBold';
  src: url('../../../fonts/InterTight-Bold.ttf') format('truetype');
}

body {
  font-family: 'InterTightBold', sans-serif;
  /* Font Styling */
}