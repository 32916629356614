/* ee.css */
.easterEggContainer {
  position: relative;
}

.hiddenText {
  opacity: 0;
  display: block;
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.5s ease 2s;
  /* 2s delay before the transition starts */
  font-size: 10px;
  /* Adjust the font size as needed */
}

.mainHeading:hover+.hiddenText {
  opacity: 1;
}