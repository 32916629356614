.nav-links {
    display: flex;
    width: 100%;
    flex-direction: row;
    font-size: 1.5rem;
}

.nav-links a {
    color: black;
    text-decoration: none;
    padding: 0 10px;
    opacity: 1;
    /* Start with 0 opacity */
    animation: fadeIn 2s ease forwards;
    /* Apply fade-in animation */
}

.nav-links a:hover {
    text-decoration: underline;
}

.active {
    font-weight: bold;
}

@media (max-width: 600px) {
    .nav-links {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        position: absolute;
        opacity: 1;
        font-size: 1rem;
    }

    .nav-links.open {
        display: flex;
    }

    .nav-links a {
        padding: 10px;

    }
}